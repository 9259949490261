import HttpServiceFAPI from "./http.serviceFAPI";

class CrudServiceFAPI {

  getUserInfos = async (userId) => {

    const endpoint = `/user/${userId}/details`;
    try {
      const response = await HttpServiceFAPI.get(endpoint);
      console.log("User info:", response);
      return response;
    } catch (error) {
      console.error("Error fetching user info:", error);
      throw error;
    }
  };

  // getUserInfos = async (userId, role) => {

  //   const endpoint = `/user/${userId}/${role}/details`;
  //   try {
  //     const response = await HttpServiceFAPI.get(endpoint);
  //     console.log("User info:", response);
  //     return response;
  //   } catch (error) {
  //     console.error("Error fetching user info:", error);
  //     throw error;
  //   }
  // };

  

  //Fonctionne avec redis. Stocke l'identifiant de l'utilisateur (celui de la BDD) et son role (celui de la connexion initiale)
  getUserUid = async (token) => {
    console.log("token dans getUserUid:", token);
    const endpoint = `/get-user-redis/${token}`;
    console.log("endpoint dans getUserUid:", endpoint);
    const response = await HttpServiceFAPI.get(endpoint);
    console.log("response dans getUserUiddd:", response);
    return response.user_uid;
  };


  PostUserUid = async (token, userUID) => {
    console.log("token dans PostUserUid:", token);
    console.log("userUID dans PostUserUid:", userUID);
    const endpoint = `/store-user-uid/`;
    const payload = { token: token, user_uid: userUID};  // Les clés doivent correspondre
    return await HttpServiceFAPI.post(endpoint, payload);  // Envoyez directement le payload
};

  // Supprimer une section par son identifiant
  deleteUserUid = async (token) => {
    const endpoint = `/delete-user-uid/${token}`;
    return await HttpServiceFAPI.delete(endpoint);
  };

   //Fonctionne avec redis. Stocke l'identifiant de l'utilisateur (celui de la BDD) et son role (celui de la connexion initiale)
   getUserRole = async (token) => {
    console.log("token dans getUserUid:", token);
    const endpoint = `/get-user-redis/${token}`;
    console.log("endpoint dans getUserRole:", endpoint);
    const response = await HttpServiceFAPI.get(endpoint);
    console.log("response dans getUserRolesss:", response.role);
    return response.role;
  };

  getUserRights = async (userId, matiereId = null) => {
    console.log("prut : ", userId, matiereId)
    try {
      let endpoint = `user-rights/${userId}/etablissement`;
      if (matiereId !== null) {
        endpoint = `user-rights/${userId}/etablissement/${matiereId}`;
      }
      const response = await HttpServiceFAPI.get(endpoint);
      console.log("response dans getUserRights:", response);
      return response;
    } catch (error) {
      console.error("Erreur lors de la récupération des droits de l'utilisateur :", error);
      throw error; // Ou gérez l'erreur selon vos besoins
    }
  };
  



  getMatieresByPromotion = async (promotionId) => {
    console.log("Liberte CRUD FAPI : ", promotionId)
    const endpoint = `matieres/promotion/${promotionId}`;
    return await HttpServiceFAPI.get(endpoint);
};



  // Récupérer toutes les promotions par section
  getPromotionsBySection = async (identifiant_section) => {
    const endpoint = `promotions/section/${identifiant_section}`;
    return await HttpServiceFAPI.get(endpoint);
  };

  // Récupérer toutes les disciplines par établissement
getMatieresByEtab = async (identifiant_etablissement) => {
  const endpoint = `etablissement/${identifiant_etablissement}/matieres`;
  return await HttpServiceFAPI.get(endpoint);
};

getMatieresByIdProf = async (prof_id) => {
  const endpoint = `/prof/${prof_id}/matieres`; // Correction ici
  return await HttpServiceFAPI.get(endpoint);
};

createMatiere = async (payload) => {
  const endpoint = "matieres";
  return await HttpServiceFAPI.post(endpoint, payload);
};



  // Récupérer une section par son identifiant
  getSectionById = async (identifiant_section) => {
    const endpoint = `sections/${identifiant_section}`;
    return await HttpServiceFAPI.get(endpoint);
  };

  // Récupérer toutes les sections par identifiant d'établissement
  getSectionsByEtabId = async (etabId) => {
    const endpoint = `sections/etablissement/${etabId}`;
    return await HttpServiceFAPI.get(endpoint);
  };

  // Créer une nouvelle section
  createSection = async (payload) => {
    const endpoint = "sections";
    return await HttpServiceFAPI.post(endpoint, payload);
  };

  // Mettre à jour une section existante par son identifiant
  updateSection = async (sectionId, payload) => {
    const endpoint = `sections/${sectionId}`;
    return await HttpServiceFAPI.put(endpoint, payload);
  };

  // Supprimer une section par son identifiant
  deleteSection = async (sectionId) => {
    const endpoint = `sections/${sectionId}`;
    return await HttpServiceFAPI.delete(endpoint);
  };


// Méthode pour la récupération de mot de passe
forgotPassword = async (email) => {
  const endpoint = "auth/password-forgot";
  const payload = { email };
  return await HttpServiceFAPI.post(endpoint, { data: { attributes: payload } });
};

// Méthode pour la réinitialisation de mot de passe
resetPassword = async (token, email, password, password_confirmation) => {
  const endpoint = "auth/password-reset";
  const payload = { token, email, password, password_confirmation };
  return await HttpServiceFAPI.post(endpoint, { data: { attributes: payload } });
};

  // users requests
  imageUpload = async (formData, id) => {
    const imageUpdate = `uploads/users/${id}/profile-image`;
    return await HttpServiceFAPI.post(imageUpdate, formData);
  };


  getEtabs = async () => {
    const usersEndpoint = "etabs";
    return await HttpServiceFAPI.get(usersEndpoint);
  };

  createEtab = async (payload) => {
    const endpoint = "etabs";
    console.log("prof stage", endpoint, payload)
    return await HttpServiceFAPI.post(endpoint, payload);
  };
  
  deleteEtab = async (identifiant_etablissement) => {
    const endpoint = `etabs/${identifiant_etablissement}`;
    return await HttpServiceFAPI.delete(endpoint);
  };

  getEtabById = async (identifiant_etablissement) => {
    const endpoint = `/etabs/${identifiant_etablissement}`;
    console.log("arbre à chat :", endpoint);
    
    try {
      // Effectuer une requête GET vers le backend
      const response = await HttpServiceFAPI.get(endpoint);
      console.log("arbre à chat :", response)
      // Vérifier la réponse (structure propre à axios)
      if (!response) {
        throw new Error(`Erreur lors de la récupération de l'établissement : ${response.statusText}`);
      }
  
      // Retourner les données de l'établissement récupéré
     
      return response;
  
    } catch (error) {
      console.error("Erreur dans getEtabById:", error);
      throw error;
    }
  };
  
  updateEtab = async (identifiant_etablissement, data) => {
    const endpoint = `/etabs/${identifiant_etablissement}`;
    try {
      const response = await HttpServiceFAPI.put(endpoint, data);
      if (!response) {
        throw new Error(`Erreur lors de la mise à jour de l'établissement : ${response.statusText}`);
      }
      return response;
    } catch (error) {
      console.error("Erreur dans updateEtab:", error);
      throw error;
    }
  };
  
  
  getFile = async (path, filename) => {
    const endpoint = `files/${path}/${filename}`;
    try {
      const response = await HttpServiceFAPI.get(endpoint, { responseType: 'blob' });
  
      // Vérifiez que la réponse contient les informations attendues
      if (!response || !response.data) {
        throw new Error("La réponse de l'API est vide ou incorrecte.");
      }
  
      return response;
    } catch (error) {
      console.error("Erreur lors de la récupération du fichier :", error);
      throw error; // Propager l'erreur pour la gérer dans le composant
    }
  };
  


  uploadFile = async (path, formData) => {
    const endpoint = `uploadfile/${path}`;
    console.log("uploadFile endpoint:", endpoint, formData);
    return await HttpServiceFAPI.post(endpoint, formData);
};

  
   // Méthode pour récupérer un parcours par ID
   getParcoursById = async (id) => {
    const endpoint = `parcours/${id}`;
    return await HttpServiceFAPI.get(endpoint);
  };

  // Méthode pour supprimer un parcours
deleteParcours = async (id_parcours) => {
    const endpoint = `parcours/${id_parcours}`;
    return await HttpServiceFAPI.delete(endpoint);
  };

addActiviteCompetence = async (payload) => {
  console.log("passer crud", payload)
  const endpoint = `activites-competences/`;
  return await HttpServiceFAPI.post(endpoint, payload);
};

// Méthode pour mettre à jour une compétence liée à une activité
updateActiviteCompetence = async (identifiant_activite, identifiant_competence, payload) => {
  const endpoint = `activites-competences/${identifiant_activite}/${identifiant_competence}`;
  return await HttpServiceFAPI.put(endpoint, payload);
};

// Méthode pour supprimer une compétence liée à une activité avec le niveau_vise_activite
deleteActiviteCompetence = async (titre_activite, numero_competence, niveau_vise_activite) => {
  const endpoint = `activites-competences/${titre_activite}/${numero_competence}/${niveau_vise_activite}`;
  return await HttpServiceFAPI.delete(endpoint);
};


  // Méthode pour récupérer les activités associées à un parcours
  getActivitesByParcoursId = async (id) => {
    const endpoint = `activites/parcours/${id}`;
    return await HttpServiceFAPI.get(endpoint);
  };


  getActiviteByDenomination = async (identifiant_activite) => {
    const endpoint = `/activites/${identifiant_activite}`;
    return await HttpServiceFAPI.get(endpoint);
  };

  // Méthode pour créer une nouvelle activité
  createActivite = async (payload) => {
    console.log("payload noupi", payload)
    const endpoint = "activites";
    return await HttpServiceFAPI.post(endpoint, payload);
  };

  // Méthode pour supprimer une activité par sa dénomination
  deleteActivite = async (identifiant_activite) => {
    const endpoint = `activites/${identifiant_activite}`;
    return await HttpServiceFAPI.delete(endpoint);
  };

    // Mettre à jour une activité existante
    updateActivite = async (identifiant_activite, payload) => {
      const endpoint = `activites/${identifiant_activite}`;
      return await HttpServiceFAPI.put(endpoint, payload);
    };
  

  // Méthode pour ajouter une nouvelle ressource
  addResource = async (formData) => {
    const endpoint = "ressources";
    try {
      const response = await HttpServiceFAPI.post(endpoint, formData);
      console.log("SAM", response)
      return response;  // Retourne les données de la réponse, y compris l'ID de la ressource ajoutée
    } catch (error) {
      console.error("Erreur lors de l'ajout de la ressource :", error);
      throw error;
    }
  };



  // Méthode pour associer une ressource à une activité
associateResourceToActivity = async (identifiant_activite, identifiant_ressource) => {
  const endpoint = `activites_ressources`;
  const payload = {
    identifiant_activite,
    identifiant_ressource // Utiliser identifiant_ressource au lieu de nom_ressource
  };
  return await HttpServiceFAPI.post(endpoint, payload);
};


   // Méthode pour récupérer les ressources associées à une activité
   getResourcesByActivite = async (identifiant_activite) => {
    const endpoint = `activites_ressources/${identifiant_activite}/ressources`;
    return await HttpServiceFAPI.get(endpoint);
  };

  // Ajouter une nouvelle matière
  createMatiere = async (payload) => {
    const endpoint = "matieres";
    return await HttpServiceFAPI.post(endpoint, payload);
  };

  // Mettre à jour une matière existante
  updateMatiere = async (identifiant_matiere, payload) => {
    const endpoint = `matieres/${identifiant_matiere}`;
    return await HttpServiceFAPI.put(endpoint, payload);
  };

  // Supprimer une matière par son identifiant
  deleteMatiere = async (identifiant_matiere) => {
    const endpoint = `matieres/${identifiant_matiere}`;
    return await HttpServiceFAPI.delete(endpoint);
  };

  // Récupérer une matière par son identifiant
  getMatiere = async (identifiant_matiere) => {
    const endpoint = `matieres/${identifiant_matiere}`;
    return await HttpServiceFAPI.get(endpoint);
  };


  // Nouvelle méthode pour récupérer toutes les disciplines
  getAllMatieres = async () => {
    const endpoint = "matieres";
    return await HttpServiceFAPI.get(endpoint);
  };

  getParcoursByMatiereId = async (matiereId) => {
    const endpoint = `parcours/matiere/${matiereId}`;
    return await HttpServiceFAPI.get(endpoint);
};


// Récupérer une section par son identifiant
getSectionById = async (identifiant_section) => {
  const endpoint = `sections/${identifiant_section}`;
  return await HttpServiceFAPI.get(endpoint);
};

// Créer une nouvelle section
createSection = async (payload) => {
  const endpoint = "sections";
  return await HttpServiceFAPI.post(endpoint, payload);
};

// Mettre à jour une section existante
updateSection = async (identifiant_section, payload) => {
  const endpoint = `sections/${identifiant_section}`;
  return await HttpServiceFAPI.put(endpoint, payload);
};

// Supprimer une section
deleteSection = async (identifiant_section) => {
  const endpoint = `sections/${identifiant_section}`;
  return await HttpServiceFAPI.delete(endpoint);
};


// Créer une nouvelle promotion
createPromotion = async (payload) => {
  const endpoint = "promotions";
  return await HttpServiceFAPI.post(endpoint, payload); // Assurez-vous que cette ligne renvoie la réponse correcte
};

 // Méthode pour créer un nouveau parcours
 createParcours = async (payload) => {
  const endpoint = "parcours";
  return await HttpServiceFAPI.post(endpoint, payload);
};

 // Méthode pour mettre à jour un parcours
 updateParcours = async (id_parcours, payload) => {
  const endpoint = `parcours/${id_parcours}`;
  return await HttpServiceFAPI.put(endpoint, payload);
};

// Mettre à jour une promotion existante
updatePromotion = async (identifiant_promotion, payload) => {
  const endpoint = `promotions/${identifiant_promotion}`;
  return await HttpServiceFAPI.put(endpoint, payload);
};

// Supprimer une promotion
deletePromotion = async (identifiant_promotion) => {
  const endpoint = `promotions/${identifiant_promotion}`;
  return await HttpServiceFAPI.delete(endpoint);
};

getElevesByPromotion = async (promotionId) => {
  const endpoint = `eleves/promotion/${promotionId}`;
  return await HttpServiceFAPI.get(endpoint);
};

// // Récupérer les blocs et compétences avec les points remportés pour un élève donné
// getBlocsAndCompetencesForEleve = async (identifiant_eleve) => {
//   const endpoint = `/eleve/${identifiant_eleve}/competences`;
//   return await HttpServiceFAPI.get(endpoint);
// };

// Récupérer les blocs et compétences avec les points remportés pour un élève donné dans un référentiel spécifique
getBlocsAndCompetencesForEleve = async (identifiant_eleve) => {
  console.log("blabla : ",identifiant_eleve )
  const endpoint = `/eleve/${identifiant_eleve}/competences`;
  console.log("blabla endpoint", endpoint)
  const response = await HttpServiceFAPI.get(endpoint);
  console.log("blabla response", response)
  return response
};

// Récupérer les blocs et compétences avec les points remportés pour tous les élèves d'une promotion dans un référentiel spécifique
getBlocsAndCompetencesForPromotion = async (identifiant_promotion, referentiel_id) => {
  console.log("getBlocsAndCompetencesForPromotion", identifiant_promotion, referentiel_id)
  const endpoint = `/promotion/${identifiant_promotion}/competences?referentiel_id=${referentiel_id}`;
  const response = await HttpServiceFAPI.get(endpoint);
  console.log("getBlocsAndCompetencesForPromotionresponse", response);
  return response
};


// Récupérer un élève par son identifiant
getEleveById = async (id_eleve) => {
  const endpoint = `eleves/${id_eleve}`;
  return await HttpServiceFAPI.get(endpoint);
};

// Créer un nouvel élève
createEleve = async (payload) => {
  const endpoint = "/register/eleve";
  return await HttpServiceFAPI.post(endpoint, payload);
};

// Mettre à jour un élève existant
updateEleve = async (id_eleve, payload) => {
  const endpoint = `eleves/${id_eleve}`;
  return await HttpServiceFAPI.put(endpoint, payload);
};

// Supprimer un élève
deleteEleve = async (id_eleve) => {
  const endpoint = `eleves/${id_eleve}`;
  return await HttpServiceFAPI.delete(endpoint);
};

// Récupérer tous les référentiels
getAllReferentiels = async () => {
  const endpoint = "referentiels";
  return await HttpServiceFAPI.get(endpoint);
};
getAllBlocs = async () => {
  const endpoint = `blocs`;
  return await HttpServiceFAPI.get(endpoint);
};

getBlocById = async (numero_bloc) => {
  const endpoint = `blocs/${numero_bloc}`;
  return await HttpServiceFAPI.get(endpoint);
};

createBloc = async (bloc) => {
  console.log("createBloc", bloc);
  const endpoint = `blocs`;
  return await HttpServiceFAPI.post(endpoint, bloc);
};

updateBloc = async (numero_bloc, bloc) => {
  const endpoint = `blocs/${numero_bloc}`;
  return await HttpServiceFAPI.put(endpoint, bloc);
};

deleteBloc = async (numero_bloc) => {
  const endpoint = `blocs/${numero_bloc}`;
  return await HttpServiceFAPI.delete(endpoint);
};


getBlocsByReferentielId = async (identifiant_referentiel) => {
  const endpoint = `blocs/referentiel/${identifiant_referentiel}`;
  return await HttpServiceFAPI.get(endpoint);
};


// Récupérer toutes les compétences d'un bloc spécifique
getCompetencesByBlocId = async (identifiant_bloc) => {
  const endpoint = `blocs/${identifiant_bloc}/competences`;
  return await HttpServiceFAPI.get(endpoint);
};

getAllReferentiels = async () => {
  const endpoint = "/referentiels";
  try {
    const response = await HttpServiceFAPI.get(endpoint);
    console.log("plante : ", response)
    return response.referentiels; // Retourne la liste des référentiels
  } catch (error) {
    console.error("Erreur lors de la récupération des référentiels :", error.message);
    throw error;
  }
};

associateReferentielToPromotion = async (promotionId, referentielId) => {
  const endpoint = `/promotions/${promotionId}/referentiel/${referentielId}`;
  try {
    const response = await HttpServiceFAPI.post(endpoint);
    return response.message; // Confirmation message
  } catch (error) {
    console.error("Erreur lors de l'association du référentiel à la promotion :", error.message);
    throw error;
  }
};

disassociateReferentielFromPromotion = async (promotionId) => {
  const endpoint = `/promotions/${promotionId}/referentiel`;
  try {
    const response = await HttpServiceFAPI.delete(endpoint);
    return response.message; // Confirmation message
  } catch (error) {
    console.error("Erreur lors de la dissociation du référentiel de la promotion :", error.message);
    throw error;
  }
};


// Récupérer un référentiel par son identifiant
getReferentielById = async (id_referentiel) => {
  const endpoint = `referentiels/${id_referentiel}`;
  return await HttpServiceFAPI.get(endpoint);
};

// Créer un nouveau référentiel
createReferentiel = async (payload) => {
  console.log("createReferentiel", payload);
  const endpoint = "/referentiels";
  return await HttpServiceFAPI.post(endpoint, payload);
};

// Mettre à jour un référentiel existant
updateReferentiel = async (id_referentiel, payload) => {
  const endpoint = `referentiels/${id_referentiel}`;
  return await HttpServiceFAPI.put(endpoint, payload);
};

// Supprimer un référentiel
deleteReferentiel = async (id_referentiel) => {
  const endpoint = `referentiels/${id_referentiel}`;
  return await HttpServiceFAPI.delete(endpoint);
};

// Ajouter dans CrudServiceFAPI
getCompetencesByReferentiel = async (identifiant_referentiel) => {
  console.log("getCompetencesByReferentiel", identifiant_referentiel)
  const endpoint = `competences/referentiel/${identifiant_referentiel}`;
  return await HttpServiceFAPI.get(endpoint);
};

// Récupérer une compétence par son identifiant
getCompetenceById = async (identifiant_competence) => {
  const endpoint = `competences/${identifiant_competence}`;
  return await HttpServiceFAPI.get(endpoint);
};

getCompetencesUnitesByEleveId = async (eleve_id, referentiel_id) => {
  const endpoint = `eleve/${eleve_id}/competences/unites?referentiel_id=${referentiel_id}`;
  return await HttpServiceFAPI.get(endpoint);
};

getCompetencesUnitesByPromotionId = async (promotion_id, referentiel_id) => {
  const endpoint = `promotion/${promotion_id}/competences/unites?referentiel_id=${referentiel_id}`;
  const response = await HttpServiceFAPI.get(endpoint);
  return response; // Assurez-vous que les données sont dans response.data
};



  // Méthode pour récupérer toutes les compétences liées à une activité
  getCompetencesByActivite = async (identifiant_activite) => {
    const endpoint = `activites/${identifiant_activite}/competences`;
    return await HttpServiceFAPI.get(endpoint);
  };

  // Ajouter une nouvelle compétence validée pour une validation donnée
addCompetenceValidation = async (competenceData) => {
  console.log("addCompetenceValidation", competenceData);
  const endpoint = `/competences/validation/`;
  return await HttpServiceFAPI.post(endpoint, competenceData);
};

createValidation = async (validationPayload) => {
  console.log("Monk 12 : ", validationPayload)
  const endpoint = "validations/create";
  return await HttpServiceFAPI.post(endpoint, validationPayload);
};

// Récupérer la validation la plus récente pour une activité d'un élève
getLatestValidationForActivity = async (validationPayload) => {
  console.log("yvan0 : ", validationPayload)
  const endpoint = `validations/${validationPayload.identifiant_eleve}/${validationPayload.identifiant_activite}/latest`;
  return await HttpServiceFAPI.get(endpoint);
};


// Récupérer toutes les validations en cours de vérification pour un professeur donné et une matière spécifique
getValidationsByProfMatiere = async (profId, identifiant_matiere) => {
  console.log("Monk bis : ", profId, identifiant_matiere)
  const endpoint = `/validations/activites/prof/${profId}/${identifiant_matiere}`;
  return await HttpServiceFAPI.get(endpoint);
};

// Récupérer toutes les validations pour un élève donné et une matière spécifique
getValidationsByEleveMatiere = async (eleveId, identifiant_matiere) => {
  const endpoint = `/validations/activites/eleve/${eleveId}/${identifiant_matiere}`;
  return await HttpServiceFAPI.get(endpoint);
};

// Récupérer les ratios des points remportés pour un élève donné pour un parcours spécifique sous forme de tableau pour le chart bar target
getRatiosByEleveParcour = async (eleve_id, parcours_id) => {
  const endpoint = `/ratios/activites/eleve/${eleve_id}/${parcours_id}`;
  return await HttpServiceFAPI.get(endpoint);
};


// Récupérer les ratios des points remportés pour un élève donné pour tous les parcours sous forme de tableau pour le chart bar target
getRatiosByEleveParcours = async (eleve_id, matiere_id) => {
  const endpoint = `/eleve/${eleve_id}/points-parcours/${matiere_id}`;
  return await HttpServiceFAPI.get(endpoint);
};

// Récupérer les ratios des points remportés pour un élève donné pour tous les parcours sous forme de tableau pour le chart bar target
getRatiosByPromotionParcours = async (promotion_id, matiere_id) => {
  const endpoint = `/promotion/${promotion_id}/points-parcours/${matiere_id}`;
  return await HttpServiceFAPI.get(endpoint);
};

// Récupérer les ratios des points remportés pour un élève donné pour tous les parcours sous forme de tableau pour le chart bar target
getRatiosByEleveActivites = async (eleve_id, matiere_id, parcours_id) => {
  const endpoint = `/eleve/${eleve_id}/points-activites/${matiere_id}/${parcours_id}`;
  return await HttpServiceFAPI.get(endpoint);
};

getRatiosByEleveActivitesSum = async (eleve_id, matiere_id, parcours_id) => {
  console.log("james : ", eleve_id, matiere_id, parcours_id)
  const endpoint = `/eleve/${eleve_id}/ratio/${matiere_id}/${parcours_id}`;
  const response = await HttpServiceFAPI.get(endpoint);
  console.log("james response : ", response)
  return response
};

getRatiosByPromotionActivites = async (promotion_id, matiere_id, parcours_id) => {
  const endpoint = `/promotion/${promotion_id}/points-activites/${matiere_id}/${parcours_id}`;
  return await HttpServiceFAPI.get(endpoint);
};

associateUserRoleToEtab = async (identifiant_utilisateur, identifiant_etablissement, role) => {
  const endpoint = `/utilisateurs_etablissements`;
  const payload = {
    identifiant_utilisateur: identifiant_utilisateur,
    identifiant_etablissement: identifiant_etablissement,
    role: role,  // Le rôle peut être "professeur" ou "gestionnaire"
  };

  try {
    const response = await HttpServiceFAPI.post(endpoint, payload);
    if (!response) {
      throw new Error(`Erreur lors de l'association de ${role} à l'établissement : ${response.statusText}`);
    }
    return response;
  } catch (error) {
    console.error(`Erreur dans associateUserRoleToEtab (${role}):`, error);
    throw error;
  }
};


dissociateUserRoleFromEtab = async (identifiant_utilisateur, identifiant_etablissement, role) => {
  const endpoint = `/utilisateurs_etablissements`;
  const payload = {
    identifiant_utilisateur: identifiant_utilisateur,
    identifiant_etablissement: identifiant_etablissement,
    role: role,  // Le rôle peut être "professeur" ou "gestionnaire"
  };

  try {
    const response = await HttpServiceFAPI.delete(endpoint, { data: payload });
    if (!response) {
      throw new Error(`Erreur lors de la dissociation de ${role} de l'établissement : ${response.statusText}`);
    }
    return response;
  } catch (error) {
    console.error(`Erreur dans dissociateUserRoleFromEtab (${role}):`, error);
    throw error;
  }
};


getUsersByEtabAndRole = async (identifiant_etablissement, role) => {
  const endpoint = `/etabs/${identifiant_etablissement}/${role}`;
  
  try {
    const response = await HttpServiceFAPI.get(endpoint);
    
    if (!response) {
      throw new Error(`Erreur lors de la récupération des ${role}s : ${response.statusText}`);
    }

    return response;  // Retourne la liste des utilisateurs
  } catch (error) {
    console.error(`Erreur dans getUsersByEtabAndRole (${role}):`, error);
    throw error;
  }

  
};

getAllUtilisateurs = async () => {
  const endpoint = '/utilisateurs';
  
  try {
    const response = await HttpServiceFAPI.get(endpoint);
    
    if (!response) {
      throw new Error(`Erreur lors de la récupération des utilisateurs : ${response.statusText}`);
    }

    return response;  // Retourne la liste des utilisateurs
  } catch (error) {
    console.error('Erreur dans getAllUtilisateurs :', error);
    throw error;
  }
};

getUtilisateursByEtablissement = async (identifiant_etablissement) => {
  const endpoint = `/etablissements/${identifiant_etablissement}/utilisateurs`;
  
  try {
    const response = await HttpServiceFAPI.get(endpoint);
    
    if (!response) {
      throw new Error(`Erreur lors de la récupération des utilisateurs pour l'établissement ${identifiant_etablissement} : ${response.statusText}`);
    }

    return response;  // Retourne la liste des utilisateurs
  } catch (error) {
    console.error(`Erreur dans getUtilisateursByEtablissement (${identifiant_etablissement}):`, error);
    throw error;
  }
};



registerUtilisateur = async (utilisateurData) => {
  const endpoint = '/register/utilisateur';
  
  try {
    const response = await HttpServiceFAPI.post(endpoint, utilisateurData);
    
    if (!response) {
      throw new Error(`Erreur lors de l'enregistrement de l'utilisateur : ${response.statusText}`);
    }

    return response;  // Retourne l'utilisateur enregistré
  } catch (error) {
    console.error('Erreur dans registerUtilisateur :', error);
    throw error;
  }
};

registerUtilisateurEtab = async (utilisateurData) => {
  const endpoint = '/register/utilisateur/etab';
  console.log("brunch 0 : ", utilisateurData)
  try {
    const response = await HttpServiceFAPI.post(endpoint, utilisateurData);

    if (!response) {
      throw new Error(`Erreur lors de l'enregistrement de l'utilisateur : ${response.statusText}`);
    }

    return response;  // Retourne les données de l'utilisateur enregistré
  } catch (error) {
    console.error('Erreur dans registerUtilisateur :', error.message);
    throw error;
  }
};


updateUtilisateur = async (identifiant_utilisateur, utilisateurData) => {
  const endpoint = `/utilisateurs/${identifiant_utilisateur}`;
  
  try {
    const response = await HttpServiceFAPI.put(endpoint, utilisateurData);
    
    if (!response) {
      throw new Error(`Erreur lors de la mise à jour de l'utilisateur ${identifiant_utilisateur} : ${response.statusText}`);
    }

    return response;  // Retourne les détails de la mise à jour
  } catch (error) {
    console.error(`Erreur dans updateUtilisateur (${identifiant_utilisateur}):`, error);
    throw error;
  }
};


deleteUtilisateur = async (identifiant_utilisateur) => {
  const endpoint = `/utilisateurs/${identifiant_utilisateur}`;
  
  try {
    const response = await HttpServiceFAPI.delete(endpoint);
    
    if (!response) {
      throw new Error(`Erreur lors de la suppression de l'utilisateur ${identifiant_utilisateur} : ${response.statusText}`);
    }

    return response;  // Retourne le succès de la suppression
  } catch (error) {
    console.error(`Erreur dans deleteUtilisateur (${identifiant_utilisateur}):`, error);
    throw error;
  }
};

updateUtilisateurEtablissement = async (profId, etab_id, utilisateurData) => {
  const endpoint = `/update/utilisateur_etablissement/${profId}/${etab_id}`;
  console.log("Mise à jour des droits utilisateur_etablissement : ", utilisateurData);
  try {
    const response = await HttpServiceFAPI.put(endpoint, utilisateurData);

    if (!response) {
      throw new Error(`Erreur lors de la mise à jour des droits utilisateur_etablissement : ${response.statusText}`);
    }

    return response;  // Retourne la réponse du serveur après la mise à jour
  } catch (error) {
    console.error('Erreur dans updateUtilisateurEtablissement :', error.message);
    throw error;
  }
};

getUtilisateursParEtablissement = async (identifiant_utilisateur) => {
  const endpoint = `/etablissement/${identifiant_utilisateur}/utilisateurs`;
  console.log("Récupération des utilisateurs pour l'établissement de : ", identifiant_utilisateur);
  
  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des utilisateurs de l'établissement : ${response.statusText}`);
    }

    return response; // Retourne la liste des utilisateurs de l'établissement
  } catch (error) {
    console.error('Erreur dans getUtilisateursParEtablissement :', error.message);
    throw error;
  }
};


getUtilisateurEtablissement = async (identifiant_utilisateur, identifiant_etablissement) => {
  const endpoint = `/get/utilisateur_etablissement/${identifiant_utilisateur}/${identifiant_etablissement}`;
  console.log("Récupération des droits utilisateur_etablissement pour : ", identifiant_utilisateur, identifiant_etablissement);
  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des droits utilisateur_etablissement : ${response.statusText}`);
    }

    return response;  // Retourne les droits de l'utilisateur dans l'établissement
  } catch (error) {
    console.error('Erreur dans getUtilisateurEtablissement :', error.message);
    throw error;
  }
};

getDroitsProfsMatieres = async (identifiant_utilisateur, identifiant_matiere) => {
  const endpoint = `/get/droits_profs_matieres/${identifiant_utilisateur}/${identifiant_matiere}`;
  console.log("Récupération des droits droits_profs_matieres pour : ", identifiant_utilisateur, identifiant_matiere);
  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des droits droits_profs_matieres : ${response.statusText}`);
    }

    return response;  // Retourne les droits du professeur pour la matière
  } catch (error) {
    console.error('Erreur dans getDroitsProfsMatieres :', error.message);
    throw error;
  }
};




addDroitsProfMatiere = async (droitsProfData) => {
  const endpoint = '/prof/droits';
  console.log("Ajout des droits pour un professeur et une matière : ", droitsProfData);
  try {
    const response = await HttpServiceFAPI.post(endpoint, droitsProfData);

    if (!response) {
      throw new Error(`Erreur lors de l'ajout des droits : ${response.statusText}`);
    }

    return response;  // Retourne la réponse du serveur après l'ajout
  } catch (error) {
    console.error('Erreur dans addDroitsProfMatiere :', error.message);
    throw error;
  }
};

getUnitesByReferentielId = async (identifiant_referentiel) => {
  try {
    console.log("unite identifiant_referentiel : ", identifiant_referentiel)
    const response = await HttpServiceFAPI.get(
      `/referentiels/${identifiant_referentiel}/unites`
    );
    return response;
  } catch (error) {
    console.error("Erreur lors de la récupération des unités :", error);
    throw error;
  }
}

getUnitesByReferentielAndPromotion = async (identifiant_referentiel, identifiant_promotion) => {
  try {
    console.log("Récupération des unités pour le référentiel :", identifiant_referentiel, "et la promotion :", identifiant_promotion);
    
    const response = await HttpServiceFAPI.get(
      `/referentiels/${identifiant_referentiel}/unites/promotion/${identifiant_promotion}`
    );
    
    return response;
  } catch (error) {
    console.error("Erreur lors de la récupération des unités pour la promotion :", error);
    throw error;
  }
};

getUnitesByReferentielAndEleve = async (identifiant_referentiel, identifiant_eleve) => {
  try {
    console.log("Récupération des unités pour le référentiel :", identifiant_referentiel, "et l'élève :", identifiant_eleve);
    
    const response = await HttpServiceFAPI.get(
      `/referentiels/${identifiant_referentiel}/unites/eleve/${identifiant_eleve}`
    );
    
    return response;
  } catch (error) {
    console.error("Erreur lors de la récupération des unités pour l'élève :", error);
    throw error;
  }
};





updateDroitsProfMatiere = async (profId, matiereId, droitsProfData) => {
  const endpoint = `/prof/${profId}/matiere/${matiereId}/droits`;
  console.log(`Mise à jour des droits pour le professeur ${profId} et la matière ${matiereId} :`, droitsProfData);
  try {
    const response = await HttpServiceFAPI.put(endpoint, droitsProfData);

    if (!response) {
      throw new Error(`Erreur lors de la mise à jour des droits : ${response.statusText}`);
    }

    return response;  // Retourne la réponse du serveur après la mise à jour
  } catch (error) {
    console.error('Erreur dans updateDroitsProfMatiere :', error.message);
    throw error;
  }
};

getAllCompetences = async () => {
  const endpoint = `competences`;
  return await HttpServiceFAPI.get(endpoint);
};
createCompetence = async (competenceData) => {
  const endpoint = `competences`;
  return await HttpServiceFAPI.post(endpoint, competenceData);
};
updateCompetence = async (identifiant_competence, competenceData) => {
  console.log("Mise à jour de la compétence : ", competenceData);
  const endpoint = `competences/${identifiant_competence}`;
  return await HttpServiceFAPI.put(endpoint, competenceData);
};

deleteCompetence = async (identifiant_competence) => {
  const endpoint = `competences/${identifiant_competence}`;
  return await HttpServiceFAPI.delete(endpoint);
};

// -------------------- Unites certificatives -----------------------
createUniteCertificative = async (unite) => {
  console.log("createUniteCertificative", unite);
  const endpoint = `unite-certificative`;
  return await HttpServiceFAPI.post(endpoint, unite);
};

getUniteCertificative = async (id) => {
  console.log("getUniteCertificative", id);
  const endpoint = `unite-certificative/${id}`;
  return await HttpServiceFAPI.get(endpoint);
};

updateUniteCertificative = async (id, unite) => {
  console.log("updateUniteCertificative", id, unite);
  const endpoint = `/unite-certificative/${id}`;
  return await HttpServiceFAPI.put(endpoint, unite);
};
 
deleteUniteCertificative = async (id) => {
  console.log("deleteUniteCertificative", id);
  const endpoint = `unite-certificative/${id}`;
  return await HttpServiceFAPI.delete(endpoint);
};


// -------------------- /Unites certificatives -----------------------

getEtablissementsParUtilisateur = async (userId) => {
  const endpoint = `/utilisateurs/${userId}/etablissements`;
  console.log("Récupération des établissements pour le professeur :", userId);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des établissements du professeur : ${response.statusText}`);
    }

    return response; // Retourne la liste des établissements du professeur
  } catch (error) {
    console.error('Erreur dans getEtablissementsParUtilisateur :', error.message);
    throw error;
  }
};

getEtablissementsParUser = async (user_id) => {
  const endpoint = `/utilisateur/${user_id}/etablissements`;
  console.log("Récupération des établissements pour le professeur :", user_id);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des établissements du professeur : ${response.statusText}`);
    }

    return response; // Retourne la liste des établissements du professeur
  } catch (error) {
    console.error('Erreur dans getEtablissementsParProf :', error.message);
    throw error;
  }
};

// Fetch sections for a professor within an établissement
getSectionsParProfEtab = async (profId, etabId) => {
  const endpoint = `/professeurs/${profId}/etablissements/${etabId}/sections`;
  try {
    const response = await HttpServiceFAPI.get(endpoint);
    return response.sections; // Assuming the response structure
  } catch (error) {
    console.error('Erreur dans getSectionsParProfEtab :', error.message);
    throw error;
  }
};

// Fetch disciplines for a professor within a promotion
getMatieresParProfPromotion = async (profId, promotionId) => {
  const endpoint = `/professeurs/${profId}/promotions/${promotionId}/matieres`;
  try {
    const response = await HttpServiceFAPI.get(endpoint);
    return response.matieres; // Assuming the response structure
  } catch (error) {
    console.error('Erreur dans getMatieresParProfPromotion :', error.message);
    throw error;
  }
};


// Fetch promotions for a professor within a section
getPromotionsParProfSection = async (user_id, sectionId) => {
  const endpoint = `/utilisateurs/${user_id}/sections/${sectionId}/promotions`;
  try {
    const response = await HttpServiceFAPI.get(endpoint);
    return response.promotions; // Assuming the response structure
  } catch (error) {
    console.error('Erreur dans getPromotionsParProfSection :', error.message);
    throw error;
  }
};



getSectionsParProf = async (profId) => {
  const endpoint = `/professeurs/${profId}/sections`;
  console.log("Récupération des sections pour le professeur :", profId);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des sections du professeur : ${response.statusText}`);
    }

    return response; // Retourne la liste des sections du professeur
  } catch (error) {
    console.error('Erreur dans getSectionsParProf :', error.message);
    throw error;
  }
};

getMatieresParProf = async (profId) => {
  const endpoint = `/professeurs/${profId}/matieres`;
  console.log("Récupération des disciplines pour le professeur :", profId);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des disciplines du professeur : ${response.statusText}`);
    }

    return response; // Retourne la liste des disciplines du professeur
  } catch (error) {
    console.error('Erreur dans getMatieresParProf :', error.message);
    throw error;
  }
};

getReferentielsParPromotion = async (promotionId) => {
  const endpoint = `/promotions/${promotionId}/referentiels`;
  console.log("Récupération du référentiel pour la promotion :", promotionId);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération du référentiel de la promotion : ${response.statusText}`);
    }
    console.log("tactique : ", response)
    return response.referentiels; // Returns the referentiel of the promotion
    
  } catch (error) {
    console.error('Erreur dans getReferentielParPromotion :', error.message);
    throw error;
  }
};




// services/cruds-serviceFAPI.js
getPromotionPoints = async (promotionId) => {
  const endpoint = `/promotions/${promotionId}/points`;
  console.log("Récupération des points pour la promotion :", promotionId);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des points de la promotion : ${response.statusText}`);
    }

    return response; // Retourne les points totaux, les points par parcours et par activité
  } catch (error) {
    console.error('Erreur dans getPromotionPoints :', error.message);
    throw error;
  }
};

getPromotionStudentsPoints = async (promotionId) => {
  const endpoint = `/promotions/${promotionId}/students/points`;
  console.log("Récupération des points par élève pour la promotion :", promotionId);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des points des élèves de la promotion : ${response.statusText}`);
    }

    return response; // Retourne les points pour chaque élève de la promotion
  } catch (error) {
    console.error('Erreur dans getPromotionStudentsPoints :', error.message);
    throw error;
  }
};

getElevePointsCompetences = async (identifiantEleve) => {
  const endpoint = `/eleves/${identifiantEleve}/points_competences`;
  console.log("Récupération des points de compétences pour l'élève :", identifiantEleve);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des points de compétences de l'élève : ${response.statusText}`);
    }
    console.log("english ", response)
    return response; // Retourne les points de compétences pour l'élève
    
  } catch (error) {
    console.error('Erreur dans getElevePointsCompetences :', error.message);
    throw error;
  }
};

// Fonction pour récupérer les points par parcours de l'élève
getElevePointsParcours = async (identifiantEleve) => {
  const endpoint = `/eleves/${identifiantEleve}/points_parcours`;
  console.log("Récupération des points par parcours pour l'élève :", identifiantEleve);
console.log("alarmeendpoint : ", endpoint)
  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des points par parcours de l'élève : ${response.statusText}`);
    }

    return response; // Retourne les points par parcours pour l'élève
  } catch (error) {
    console.error('Erreur dans getElevePointsParcours :', error.message);
    throw error;
  }
};

// Fonction pour récupérer les points par activité de l'élève
getElevePointsActivites = async (identifiantEleve) => {
  const endpoint = `/eleves/${identifiantEleve}/points_activites`;
  console.log("Récupération des points par activité pour l'élève :", identifiantEleve);
  console.log("alarmeendpoint : ", endpoint);

  try {
    const response = await HttpServiceFAPI.get(endpoint);
    console.log("responseactivite : ", response)
    if (!response) {
      throw new Error(`Erreur lors de la récupération des points par activité de l'élève : ${response.statusText}`);
    }

    return response; // Retourne les points par activité pour l'élève
  } catch (error) {
    console.error('Erreur dans getElevePointsActivites :', error.message);
    throw error;
  }
};

// Fonction pour récupérer les points par bloc de l'élève
getElevePointsBlocs = async (identifiantEleve) => {
  const endpoint = `/eleves/${identifiantEleve}/points_blocs`;
  console.log("Récupération des points par bloc pour l'élève :", identifiantEleve);
  console.log("endpoint : ", endpoint);

  try {
    const response = await HttpServiceFAPI.get(endpoint);
    console.log("responsebloc : ", response)

  

    return response; // Retourne les points par bloc pour l'élève
  } catch (error) {
    console.error('Erreur dans getElevePointsBlocs :', error.message);
    throw error;
  }
};


// Fonction pour récupérer les points par parcours de tous les élèves d'une promotion
getPromotionPointsParcours = async (identifiantPromotion) => {
  const endpoint = `/promotions/${identifiantPromotion}/points_parcours`;
  console.log("Récupération des points par parcours pour la promotion :", identifiantPromotion);
  console.log("Endpoint :", endpoint);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des points par parcours de la promotion : ${response.statusText}`);
    }

    return response; // Retourne les points par parcours pour tous les élèves de la promotion
  } catch (error) {
    console.error('Erreur dans getPromotionPointsParcours :', error.message);
    throw error;
  }
};

// Fonction pour récupérer les points par activité de tous les élèves d'une promotion
getPromotionPointsActivites = async (identifiantPromotion) => {
  const endpoint = `/promotions/${identifiantPromotion}/points_activites`;
  console.log("Récupération des points par activité pour la promotion :", identifiantPromotion);
  console.log("Endpoint :", endpoint);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des points par activité de la promotion : ${response.statusText}`);
    }

    return response; // Retourne les points par activité pour tous les élèves de la promotion
  } catch (error) {
    console.error('Erreur dans getPromotionPointsActivites :', error.message);
    throw error;
  }
};

// Fonction pour récupérer les points par bloc de tous les élèves d'une promotion
getPromotionPointsBlocs = async (identifiantPromotion) => {
  
  const endpoint = `/promotions/${identifiantPromotion}/points_blocs`;
  console.log("Récupération des points par bloc pour la promotion :", identifiantPromotion);
  console.log("Endpoint :", endpoint);

  try {
    console.log("crazy :",identifiantPromotion )
    const response = await HttpServiceFAPI.get(endpoint);


    return response; // Retourne les points par bloc pour tous les élèves de la promotion
  } catch (error) {
    console.error('Erreur dans getPromotionPointsBlocs :', error.message);
    throw error;
  }
};

getPromotionPointsCompetences = async (identifiantPromotion) => {
  console.log("semence : ", identifiantPromotion)
  const endpoint = `/promotion/${identifiantPromotion}/points_competences`;
  try {
    const response = await HttpServiceFAPI.get(endpoint);
    return response;
  } catch (error) {
    console.error("Erreur lors de la récupération des points compétences sum de la promotion :", error);
    throw error;
  }
};

// Fonction pour récupérer les points par date pour un bloc spécifique
getPointsParDateBloc = async (identifiantBloc) => {
  const endpoint = `/activites_points_par_date/${identifiantBloc}`;
  console.log("Récupération des points par date pour le bloc :", identifiantBloc);
  console.log("Endpoint :", endpoint);

  try {
    const response = await HttpServiceFAPI.get(endpoint);
    return response; // Retourne les points par date pour le bloc spécifique
  } catch (error) {
    console.error('Erreur dans getPointsParDateBloc :', error.message);
    throw error;
  }
};

// Fonction pour récupérer les points par date pour toutes les activités
getPointsParDateActivites = async () => {
  const endpoint = `/activites_points_par_date`;
  console.log("Récupération des points par date pour toutes les activités");
  console.log("Endpoint :", endpoint);

  try {
    const response = await HttpServiceFAPI.get(endpoint);
    return response; // Retourne les points par date pour toutes les activités
  } catch (error) {
    console.error('Erreur dans getPointsParDateActivites :', error.message);
    throw error;
  }
};


// Fonction pour récupérer les points par date pour toutes les activités
getPointsParDateBlocsEtTotaux = async (identifiant_promotion) => {
  const endpoint = `/points_par_blocs_et_totaux/${encodeURIComponent(identifiant_promotion)}`;
  console.log("Récupération des points par date pour toutes les activités");
  console.log("Endpoint :", endpoint);

  try {
    const response = await HttpServiceFAPI.get(endpoint);
    console.log("Réponse obtenues :", response); // Accès aux données via response.data

    // Retourner les données directement
    return response;
  } catch (error) {
    console.error('Erreur dans getPointsParDateBlocsEtTotaux :', error);
    throw error;
  }
}

getPointsParDateBlocsEtTotauxEleve= async (identifiant_eleve) => {
  const endpoint = `/points_par_blocs_et_totaux_eleve/${identifiant_eleve}`;
  console.log("Appel du endpoint :", endpoint);

  try {
    const response = await HttpServiceFAPI.get(endpoint);
    console.log("Réponse obtenues eleve :", response);
    return response; // Retourne les données reçues
  } catch (error) {
    console.error("Erreur dans getPointsParDateBlocsEtTotauxEleve :", error.message);
    throw error;
  }
};

updateUserInfos = async (userId, newUserInfo) => {
  const endpoint = `/user/${userId}/update`;
  try {
    const response = await HttpServiceFAPI.post(endpoint, newUserInfo);
    console.log("User info updated:", response);
    return response;
  } catch (error) {
    console.error("Error updating user info:", error);
    throw error;
  }
};
updateUserPassword = async (userId, payload) => {
  console.log("Debugging userId and payload:", userId, payload); // pour vérifier les valeurs
  const endpoint = `user/${userId}/change-password`;
  return await HttpServiceFAPI.put(endpoint, payload);
};

dissociateUserFromEstablishment = async (userId, etablissementId) => {
  console.log("imagine dragon : ", userId, etablissementId)
  const endpoint = `dissociate-user-from-establishment/${userId}/${etablissementId}`;
  
  try {
    const response = await HttpServiceFAPI.delete(endpoint);
    return response;
  } catch (error) {
    console.error("Erreur lors de la dissociation de l'utilisateur de l'établissement:", error);
    throw error;
  }
};

associateUserToEstablishment = async (userId, etablissementId) => {
  console.log("Associating user:", userId, "with establishment:", etablissementId);
  const endpoint = `associate-user-to-establishment/${userId}/${etablissementId}`;
  
  try {
    const response = await HttpServiceFAPI.post(endpoint);
    return response;
  } catch (error) {
    console.error("Erreur lors de l'association de l'utilisateur à l'établissement:", error);
    throw error;
  }
};

getAllUsers = async () => {
  try {
    const response = await HttpServiceFAPI.get("/users");
    return response; // Renvoie la liste des utilisateurs
  } catch (error) {
    console.error("Erreur lors de la récupération des utilisateurs :", error);
    throw error;
  }
};

// Méthode pour ajouter un utilisateur
async addUtilisateur(utilisateurData) {
  console.log("bret : ", utilisateurData)
  try {
    const response = await HttpServiceFAPI.post('/utilisateurs', utilisateurData);
    return response;
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'utilisateur:", error);
    throw error;
  }
}

// Méthode pour modifier un utilisateur
async updateUtilisateur(userId, utilisateurData) {
  try {
    const response = await HttpServiceFAPI.put(`/utilisateurs/${userId}`, utilisateurData);
    return response;
  } catch (error) {
    console.error("Erreur lors de la modification de l'utilisateur:", error);
    throw error;
  }
}

// Méthode pour supprimer un utilisateur
async deleteUtilisateur(userId) {
  try {
    const response = await HttpServiceFAPI.delete(`/utilisateurs/${userId}`);
    return response;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'utilisateur:", error);
    throw error;
  }
}

updateRoleUser = async (userId, roles, identifiant_etablissement) => {
  try {
    const response = await HttpServiceFAPI.put(`/utilisateurs/${userId}/role`, {
      identifiant_etablissement,
      roles,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour des rôles de l'utilisateur :", error);
    throw error;
  }
};

// Méthode pour mettre à jour la promotion d'un élève
async updateElevePromotion(identifiantUtilisateur, identifiantPromotion) {
  try {
    const response = await HttpServiceFAPI.put(`/eleves/${identifiantUtilisateur}/promotion`, { identifiant_promotion: identifiantPromotion });
    return response;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la promotion de l'élève:", error);
    throw error;
  }
}
// Nouvelle méthode pour obtenir la liste de tous les rôles
getRoles = async () => {
  const endpoint = "roles";  // Endpoint pour récupérer tous les rôles
  try {
    const response = await HttpServiceFAPI.get(endpoint); // Requête vers le service d'API
    console.log("Réponse dans getRoles:", response);
    return response;
  } catch (error) {
    console.error("Erreur dans getRoles:", error);
    throw error;
  }
}

createUtilisateur = async (utilisateur, identifiantEtablissement, role) => {
  console.log("createUtilisateur", utilisateur, identifiantEtablissement, role);
  
  // Base de l'endpoint
  let endpoint = `/utilisateurs/etablissement`;

  // Ajouter les paramètres de requête si identifiantEtablissement et role sont fournis
  const params = [];
  if (identifiantEtablissement) {
    params.push(`identifiant_etablissement=${encodeURIComponent(identifiantEtablissement)}`);
  }
  if (role) {
    params.push(`role=${encodeURIComponent(role)}`);
  }
  if (params.length > 0) {
    endpoint += `?${params.join('&')}`;
  }

  try {
    // Effectuer la requête POST avec les données de l'utilisateur
    const response = await HttpServiceFAPI.post(endpoint, utilisateur);
    console.log("Réponse de la création de l'utilisateur :", response)
    return response.data; // Assurez-vous que votre service HTTP retourne la réponse appropriée
  } catch (error) {
    console.error("Erreur lors de la création de l'utilisateur :", error);
    throw error; // Relancer l'erreur ou gérer différemment selon votre logique
  }
};


createUser = async (userData) => {
  const endpoint = `/utilisateurs/`;
  console.log("Création de l'utilisateur avec les données : ", userData);

  try {
    const response = await HttpServiceFAPI.post(endpoint, userData);

    if (!response) {
      throw new Error(`Erreur lors de la création de l'utilisateur : ${response.statusText}`);
    }

    return response.data;  // Retourne le message de succès et l'identifiant utilisateur
  } catch (error) {
    console.error('Erreur dans createUser :', error.message);
    throw error;
  }
};

getReferentielsByEtab = async (etablissementId) => {
  const endpoint = `/etablissements/${etablissementId}/referentiels`;
  console.log("Récupération des référentiels pour l'établissement : ", etablissementId);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des référentiels : ${response.statusText}`);
    }

    return response.data;  // Retourne les référentiels pour l'établissement spécifié
  } catch (error) {
    console.error('Erreur dans getReferentielsByEtab :', error.message);
    throw error;
  }
};

getReferentielsByProf = async (userId, etablissementId) => {
  const endpoint = `/professeur/${userId}/etablissement/${etablissementId}/referentiels`;
  console.log("Récupération des référentiels pour le professeur : ", userId, " dans l'établissement : ", etablissementId);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des référentiels pour le professeur : ${response.statusText}`);
    }

    return response;  // Retourne les référentiels associés à l'utilisateur avec le rôle de professeur dans l'établissement donné
  } catch (error) {
    console.error('Erreur dans getReferentielsByProf :', error.message);
    throw error;
  }
};

getActivitesByReferentiel = async (identifiantReferentiel) => {
  const endpoint = `/activites/all/${identifiantReferentiel}`;
  console.log("Récupération des activités pour le référentiel : ", identifiantReferentiel);

  try {
    const response = await HttpServiceFAPI.get(endpoint);

    if (!response) {
      throw new Error(`Erreur lors de la récupération des activités pour le référentiel : ${response.statusText}`);
    }

    return response;  // Retourne les activités associées au référentiel donné
  } catch (error) {
    console.error('Erreur dans getActivitesByReferentiel :', error.message);
    throw error;
  }
};

createTicket = async (ticketData, identifiant_utilisateur) => {
  try {
    // Inclure identifiant_utilisateur dans les données de la requête
    const data = { ...ticketData, identifiant_utilisateur };
    
    const response = await HttpServiceFAPI.post('/tickets', data);
    return response.data;
  } catch (error) {
    // Gestion des erreurs
    throw error.response ? error.response.data : new Error('Erreur lors de la création du ticket.');
  }
};

// 2. Récupérer tous les tickets
getAllTickets = async () => {
  try {
    const response = await HttpServiceFAPI.get('/tickets');
    return response;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erreur lors de la récupération des tickets.');
  }
};

// 3. Récupérer un ticket spécifique par ID
getTicketById = async (id) => {
  try {
    const response = await HttpServiceFAPI.get(`/tickets/${id}`);
    return response;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erreur lors de la récupération du ticket.');
  }
};

getTicketByUserId = async (user_id) => {
  try {
    const response = await HttpServiceFAPI.get(`/users/${user_id}/tickets`);
    return response;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erreur lors de la récupération du ticket.');
  }
};

// 4. Mettre à jour un ticket existant
updateTicketStatus = async (id, newStatus) => {
  try {
    const response = await HttpServiceFAPI.put(`/tickets/${id}/status`, { status: newStatus });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erreur lors de la mise à jour du statut du ticket.');
  }
};


renewToken = async () => {
  try {
    const response = await HttpServiceFAPI.post('/renew-token');
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error('Erreur lors du renouvellement du jeton.');
  }
};

// 6. Accéder à une route protégée
protectedRoute = async () => {
  try {
    const response = await HttpServiceFAPI.get('/protected-route');
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error('Erreur lors de l\'accès à la route protégée.');
  }
};

// Méthode pour récupérer les activités associées à un parcours
getActivitesEvaluationsByEleveId = async (identifiant_eleve) => {
  console.log("getActivitesEvaluationsByEleveId", identifiant_eleve)
  const endpoint = `activites/evaluations/${identifiant_eleve}`;
  const response = await HttpServiceFAPI.get(endpoint);
  console.log("EDF", response)
  return response
};

getActivitesEvaluationsByPromotionId = async (identifiant_promotion) => {
  console.log("getActivitesEvaluationsByEleveId", identifiant_promotion)
  const endpoint = `activites/evaluations/promotion/${identifiant_promotion}`;
  const response = await HttpServiceFAPI.get(endpoint);
  console.log("EDF", response)
  return response
};

getActivitesIsEvalByPromotionId = async (identifiant_promotion, identifiant_matiere) => {
  console.log("getActivitesEvaluationsByEleveId", identifiant_promotion, identifiant_matiere)
  const endpoint = `/activites/is_eval/promotion/${identifiant_promotion}/matiere/${identifiant_matiere}/evaluations`;
  const response = await HttpServiceFAPI.get(endpoint);
  console.log("EDF", response)
  return response
};

createActiviteIA = async (parcours_id, identifiant_evaluation) => {
  const endpoint = `generer_activite_finale/${parcours_id}/${identifiant_evaluation}`;
  return await HttpServiceFAPI.get(endpoint);
}


// getStatusEvaluationsByEleveId = async (identifiant_parcours, identifiant_eleve) => {
//   try {
//     console.log("getStatusEvaluationsByEleveId", identifiant_parcours, identifiant_eleve);
    
//     // Construction du nouvel endpoint
//     const endpoint = `parcours/${identifiant_parcours}/eleve/${identifiant_eleve}/evaluations`;
//     console.log("endpoint", endpoint);
    
//     // Appel de l'API via HttpServiceFAPI (supposant qu'il utilise Axios)
//     const response = await HttpServiceFAPI.get(endpoint);
//     console.log("response dede", response);
    
//     // Accéder aux données réelles
//     if (response && response.data) {
//       return response.data;
//     } else {
//       console.warn("Aucune donnée reçue de l'API.");
//       return [];
//     }
//   } catch (error) {
//     // Vérifier si l'erreur a une réponse
//     if (error.response) {
//       console.error("Erreur lors de la récupération des évaluations :", error.response.data);
//       return error.response.data; // ou gérer l'erreur selon vos besoins
//     } else {
//       console.error("Erreur lors de la récupération des évaluations :", error.message);
//       throw error; // Relancer l'erreur pour la gestion ultérieure
//     }
//   }
// };

getEvaluationsByEleveId = async (identifiant_parcours, identifiant_eleve) => {
  try {
    console.log("getStatusEvaluationsByEleveId", identifiant_parcours, identifiant_eleve);
    
    // Construction du nouvel endpoint
    const endpoint = `parcours/${identifiant_parcours}/eleve/${identifiant_eleve}/evaluations`;
    console.log("endpoint", endpoint);
    
    // Appel de l'API via HttpServiceFAPI
    const response = await HttpServiceFAPI.get(endpoint);
    console.log("Réponse complète de l'API :", response);
    console.log("Données reçues de l'API :", response.data);
    
    // Accéder aux données réelles
    if (response) {
      console.log("Évaluations reçues :", response);
      return response;
    } else {
      console.warn("Aucune donnée reçue de l'API.");
      return [];
    }
  } catch (error) {
    if (error.response) {
      console.error("Erreur de réponse de l'API :", error.response.data);
    } else {
      console.error("Erreur lors de la récupération des évaluationss :", error.message);
    }
    throw error; // Relancer l'erreur pour la gestion ultérieure
  }
};

getStatusEvaluationsByEleveId = async (identifiant_parcours, identifiant_eleve) => {
  try {
    console.log("getStatusEvaluationsByEleveId", identifiant_parcours, identifiant_eleve);
    
    // Construction du nouvel endpoint
    const endpoint = `parcours/${identifiant_parcours}/eleve/${identifiant_eleve}/validations`;
    console.log("endpoint", endpoint);
    
    // Appel de l'API via HttpServiceFAPI
    const response = await HttpServiceFAPI.get(endpoint);
    console.log("Réponse complète de l'API :", response);
    console.log("Données reçues de l'API :", response.data);
    
    // Accéder aux données réelles
    if (response) {
      console.log("Évaluations reçues :", response);
      return response;
    } else {
      console.warn("Aucune donnée reçue de l'API.");
      return [];
    }
  } catch (error) {
    if (error.response) {
      console.error("Erreur de réponse de l'API :", error.response.data);
    } else {
      console.error("Erreur lors de la récupération des évaluationss :", error.message);
    }
    throw error; // Relancer l'erreur pour la gestion ultérieure
  }
};


createEvaluation = async (evaluationData) => {
  try {
    console.log("createEvaluation", evaluationData);

    // Construction du nouvel endpoint
    const endpoint = `evaluations/`;
    console.log("endpoint", endpoint);

    // Appel de l'API via HttpServiceFAPI
    const response = await HttpServiceFAPI.post(endpoint, evaluationData);
    console.log("response", response);

    // Vérification de la réponse
    if (response && response.message) {
      return response.message;
    } else {
      console.warn("Échec de la création de l'évaluation.");
      return null;
    }
  } catch (error) {
    console.error("Erreur lors de la création de l'évaluation :", error);
    throw error; // Relancer l'erreur pour la gestion ultérieure
  }
};

createEvaluationSoft = async (evaluationData) => {
  try {
    console.log("createEvaluationSoft", evaluationData);

    // Construction du nouvel endpoint
    const endpoint = `evaluations/demande`;
    console.log("endpoint", endpoint);

    // Appel de l'API via HttpServiceFAPI
    const response = await HttpServiceFAPI.post(endpoint, evaluationData);
    console.log("response", response);

    // Vérification de la réponse
    if (response && response.message) {
      return response.message;
    } else {
      console.warn("Échec de la création de l'évaluation.");
      return null;
    }
  } catch (error) {
    console.error("Erreur lors de la création de l'évaluation :", error);
    throw error; // Relancer l'erreur pour la gestion ultérieure
  }
};

updateEvaluation = async (identifiantEvaluation, evaluationData) => {
  try {
    console.log("Début de updateEvaluation", { identifiantEvaluation, evaluationData });

    // Construction de l'URL du nouvel endpoint
    const endpoint = `evaluations/${identifiantEvaluation}`;
    console.log("URL de l'endpoint :", endpoint);

    // Appel de l'API via HttpServiceFAPI
    const response = await HttpServiceFAPI.put(endpoint, evaluationData);

    // Vérification de la réponse
    if (response && response.message) {
      console.log("Mise à jour réussie :", response.message);
      return response.message;
    } else {
      console.warn("Aucune réponse valide reçue pour la mise à jour.");
      return "Erreur : Réponse inattendue de l'API.";
    }
  } catch (error) {
    // Gestion et journalisation des erreurs
    if (error.response) {
      console.error("Erreur API :", error.response.data);
      throw new Error(error.response.data.detail || "Erreur inconnue côté serveur.");
    } else if (error.request) {
      console.error("Erreur de requête :", error.request);
      throw new Error("Aucune réponse du serveur. Vérifiez votre connexion réseau.");
    } else {
      console.error("Erreur inattendue :", error.message);
      throw new Error("Erreur lors de la mise à jour de l'évaluation.");
    }
  }
};


getAllEvaluationByPromotion = async (identifiant_promotion, identifiant_matiere) => {
  try {
    console.log("Récupération de toutes les évaluations...");

    // Endpoint pour récupérer toutes les évaluations
    const endpoint = `/promotions/${identifiant_promotion}/matieres/${identifiant_matiere}/evaluations`;

    // Appel de l'API
    const response = await HttpServiceFAPI.get(endpoint);

    console.log("Réponse de l'API :", response);

    if (response) {
      return response; // Retourner la liste des évaluations
    } else {
      console.warn("Aucune donnée reçue de l'API.");
      return [];
    }
  } catch (error) {
    // Vérifier si l'erreur est un 404
    if (error.response && error.response.status === 404) {
      console.warn("Aucune évaluation trouvée pour cette promotion. Retour d'une liste vide.");
      return []; // Retourner une liste vide
    } else {
      console.error("Erreur lors de la récupération des évaluations :", error);
      throw error; // Relancer l'erreur pour gestion ultérieure
    }
  }
};

getAllEvaluationByEleveId = async (identifiant_eleve, identifiant_matiere) => {
  try {
    console.log("Récupération de toutes les évaluations...");

    // Endpoint pour récupérer toutes les évaluations
    const endpoint = `/eleves/${identifiant_eleve}/matieres/${identifiant_matiere}/evaluations`;

    // Appel de l'API
    const response = await HttpServiceFAPI.get(endpoint);

    console.log("Réponse de l'API :", response);

    if (response) {
      return response; // Retourner la liste des évaluations
    } else {
      console.warn("Aucune donnée reçue de l'API.");
      return [];
    }
  } catch (error) {
    // Vérifier si l'erreur est un 404
    if (error.response && error.response.status === 404) {
      console.warn("Aucune évaluation trouvée pour cette promotion. Retour d'une liste vide.");
      return []; // Retourner une liste vide
    } else {
      console.error("Erreur lors de la récupération des évaluations :", error);
      throw error; // Relancer l'erreur pour gestion ultérieure
    }
  }
};


deleteEvaluation = async (identifiant_evaluation) => {
  try {
    console.log(`Suppression de l'évaluation avec l'identifiant: ${identifiant_evaluation}...`);
    
    // Endpoint pour supprimer une évaluation spécifique
    const endpoint = `/evaluations/${identifiant_evaluation}`;
    
    // Appel de l'API avec la méthode DELETE
    const response = await HttpServiceFAPI.delete(endpoint);
    
    console.log("Réponse de l'API :", response);
    
    if (response) {
      return response; // Retourner la réponse de l'API (par exemple, un message de succès)
    } else {
      console.warn("Aucune donnée reçue de l'API.");
      return { message: "Évaluation supprimée avec succès." };
    }
  } catch (error) {
    console.error("Erreur lors de la suppression de l'évaluation :", error);
    throw error; // Relancer l'erreur pour gestion ultérieure
  }
};


// 5. Supprimer un ticket spécifique
deleteTicket = async (id) => {
  try {
    const response = await HttpServiceFAPI.delete(`/tickets/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erreur lors de la suppression du ticket.');
  }
};

}

export default new CrudServiceFAPI();
