import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexChartBarTargetMultiple = ({ seriesData }) => {
  const options = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
        dataLabels: {
          position: 'top', // Position des étiquettes des barres
        },
      },
    },
    colors: ['#00E396', '#775DD0'], // Couleurs pour les séries "Entraînement" et "Évaluation"
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      markers: {
        fillColors: ['#00E396', '#775DD0'],
      },
    },
    xaxis: {
      type: 'category', // Définir le type de l'axe des x à 'category'
      labels: {
        rotate: -45, // Optionnel : rotation des étiquettes
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      title: {
        text: 'Valeurs',
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    // Activation des goals
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    // Options pour les goals
    annotations: {
      position: 'front',
    },
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={seriesData} type="bar" height={350} />
    </div>
  );
};

export default ApexChartBarTargetMultiple;
