// AuthContextProvider.js

import { createContext, useContext, useEffect, useMemo, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import SecureLS from "secure-ls";
import { Ability, defineAbility } from "@casl/ability"; // CASL pour les permissions
import useStore from "services/store"; // Zustand pour la gestion d'état

// Initialiser SecureLS pour le stockage sécurisé
const secureLocalStorage = new SecureLS({ encodingType: "aes" });

// Création du contexte AuthContext
export const AuthContext = createContext({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  getRole: () => {},
  loading: true,
  userUid: null,
  userIdentite: null,
  role: null,
});

// Contexte CASL pour les permissions
const defaultAbility = new Ability([]); // Aucune permission par défaut

// Provider pour AuthContext
export const AuthContextProvider = ({ children }) => {
  const [ability, setAbility] = useState(defaultAbility); // État pour CASL
  const [userUid, setUserUid] = useState(null);
  const [userIdentite, setUserIdentite] = useState(null);
  const { role, setRole } = useStore(); // Utilisation de Zustand pour obtenir et stocker le rôle
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const selectedEtab = useStore((state) => state.selectedEtab);
  const selectedMatiere = useStore((state) => state.selectedMatiere);
  

  const navigate = useNavigate();

// Fonction de renouvellement du jeton
const renewToken = async () => {
  if (!token) return;

  try {
    const response = await crudsServiceFAPI.renewToken();
    console.log("Jeton renouvelé avec succès");
    // Optionnel: Si un nouveau jeton est retourné, mettez à jour le stockage
    // setToken(response.new_access_token);
    // localStorage.setItem("access_token", response.new_access_token);
  } catch (error) {
    console.error("Erreur lors du renouvellement du jeton :", error);
    logout();
  }
};

useEffect(() => {
  if (!isAuthenticated) return;

  const events = ["click", "keydown", "touchstart"];
  let renewTimeout;

  const resetTimeout = () => {
    if (renewTimeout) clearTimeout(renewTimeout);
    // Renouveler le jeton après 25 minutes d'inactivité
    renewTimeout = setTimeout(() => {
      renewToken();
    }, 25 * 60 * 1000); // 25 minutes
  };

  const eventHandler = () => {
    renewToken();
    resetTimeout();
  };

  events.forEach((event) => window.addEventListener(event, eventHandler));

  resetTimeout();

  return () => {
    events.forEach((event) => window.removeEventListener(event, eventHandler));
    if (renewTimeout) clearTimeout(renewTimeout);
  };
}, [isAuthenticated, token]);



  const location = useLocation();
  console.log("froid", loading)
  const setDefaultPermissions = () => {
    return defineAbility((can) => {    
      // Droits spécifiques pour le rôle "eleve"
      can("view", "parcours");
      can("view", "activite");
      can("view", "validation");
    });
  };

  // Chargement des données utilisateur lors de la connexion
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        console.log("token", token)
        try {
          console.log("token2", token)
          
          const userUID = await crudsServiceFAPI.getUserUid(token);
          setUserUid(userUID);
          const identite = await crudsServiceFAPI.getUserInfos(userUID);
          console.log("balade ", identite)
          setUserIdentite(identite);
          if(role){
          const currentRole = role
          
          //const currentRole = await crudsServiceFAPI.getUserRole(token);
          
          console.log("delinc", currentRole, identite, currentRole)
          setRole(currentRole);
          
          secureLocalStorage.set("userIdentite", identite, currentRole);
          // console.log("castor", identite)
          if (currentRole === "superadministrateur") {
            setAbility(defineAbility((can) => can("manage", "all")));
            setLoading(false);
            return;
          } else if (currentRole === "eleve") {     
            setAbility(setDefaultPermissions());
            setLoading(false);
          } else {
            console.log("hypi")
            // Récupération des droits et mise à jour de CASL
            let rights = null
            if(selectedMatiere){
            rights = await crudsServiceFAPI.getUserRights(userUID, selectedMatiere.identifiant_matiere);
            console.log("delinc pouce matieres", rights, selectedEtab)
            }else{
              rights = await crudsServiceFAPI.getUserRights(userUID);
              console.log("delinc pouce no matiere", rights)
            }
            if ((rights && rights.length > 0) && selectedEtab && selectedMatiere ) {
              console.log("delinc pouce map", rights, selectedEtab.identifiant_etablissement)
              const droit = rights.find(d => d.identifiant_etablissement === selectedEtab.identifiant_etablissement);
              const newAbility = mapRightsToPermissions(droit, selectedMatiere);
              setAbility(newAbility);
              setLoading(false);
            } else if ((rights && rights.length > 0) && selectedEtab){
              console.log("delinc pouce map no matiere", rights, selectedEtab.identifiant_etablissement)
              const droit = rights.find(d => d.identifiant_etablissement === selectedEtab.identifiant_etablissement);
              console.log("delinc pouce map droit : ", droit)
              const newAbility = mapRightsToPermissions(droit);
              setAbility(newAbility);
              setLoading(false);
            }else{
              setAbility(defineAbility(() => {}));
              setLoading(false);
            }
          }
          }
          setLoading(false);

        } catch (error) {
          setLoading(false);
          setIsAuthenticated(false);
          console.error("Erreur de récupération des données utilisateurs :", error);
          logout()          
        }
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    };
    fetchUserData();
  }, [token, selectedEtab, role, selectedMatiere, navigate]);

  // Gestion de l'authentification et redirection
  useEffect(() => {
    console.log("cacahuette : ", isAuthenticated)
    if (!token) {
      setIsAuthenticated(false);
      navigate("/auth/login");
    }
  }, []);

 
  // Fonction de connexion
  const login = async (newToken, userUID) => {
    console.log("haris : ", newToken, userUID )
    localStorage.setItem("token", newToken);
    setToken(newToken); // Mettre à jour l'état local du token
    await crudsServiceFAPI.PostUserUid(newToken, userUID);
    setIsAuthenticated(true);
    navigate("/parcours");
    
  };

  // Fonction de déconnexion
  // AuthContextProvider.js (suite)

const logout = async () => {
  try {
    
    setIsAuthenticated(false);
    setUserUid(null)
    setUserIdentite(null)
    setRole(null)
    // Appel au service de déconnexion
    await crudsServiceFAPI.deleteUserUid(token);
    localStorage.removeItem("token");
    setToken(null);
    localStorage.removeItem("user-store");
    secureLocalStorage.clear();
    
    // Efface le stockage persistant de Zustand
    await useStore.persist.clearStorage();
    
    // Réinitialise le store Zustand
    useStore.getState().reset();
    
    // Réinitialisation de CASL
    setAbility(defineAbility(() => {}));
    
    // Met à jour l'état d'authentification
    
    console.log("gugule0", isAuthenticated)
    // Redirection vers la page de login
    navigate("/auth/login");
    setLoading(false);
  } catch (error) {
    console.error("Erreur lors de la déconnexion :", error);
  }
};


  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        getRole: () => role,
        loading,
        userUid,
        userIdentite,
        role,
        ability,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Fonction pour mapper les droits utilisateur en permissions CASL
const mapRightsToPermissions = (rights, selectedMatiere = null) => {
  console.log("mapRightsToPermissions", rights, selectedMatiere);
  if (!rights) return defineAbility(() => {});

  return defineAbility((can) => {
    const droitsArray = Array.isArray(rights) ? rights : [rights];
    droitsArray.forEach((droit) => {
      if (!droit) return;

      // Condition pour la matière si selectedMatiere est défini et a une propriété identifiant_matiere
      const matiereCondition = selectedMatiere && selectedMatiere.identifiant_matiere
        ? { matiere: selectedMatiere.identifiant_matiere.toString() }
        : null;

      // Droits pour les sections
      if (droit.view_sections) can("view", "section");
      if (droit.create_sections) can("create", "section");
      if (droit.edit_sections) can("edit", "section");
      if (droit.delete_sections) can("delete", "section");

      // Droits pour les promotions
      if (droit.view_promotions) can("view", "promotion");
      if (droit.create_promotions) can("create", "promotion");
      if (droit.edit_promotions) can("edit", "promotion");
      if (droit.delete_promotions) can("delete", "promotion");

      // Droits pour les établissements
      if (droit.create_etablissements) can("create", "etablissement");
      if (droit.edit_etablissements) can("edit", "etablissement");
      if (droit.delete_etablissements) can("delete", "etablissement");

      // Droits pour les disciplines
      if (droit.view_matieres) can("view", "matiere");
      if (droit.create_matieres) can("create", "matiere");
      if (droit.edit_matieres) can("edit", "matiere");
      if (droit.delete_matieres) can("delete", "matiere");

      // Droits pour les élèves
      if (droit.create_eleves) can("create", "eleve");
      if (droit.edit_eleves) can("edit", "eleve");

      // Droits pour les professeurs
      if (droit.create_professeurs) can("create", "professeur");
      if (droit.edit_professeurs) can("edit", "professeur");

      // Droits pour les coordonateurs
      if (droit.create_gestionnaires) can("create", "gestionnaire");
      if (droit.edit_gestionnaires) can("edit", "gestionnaire");
      if (droit.delete_gestionnaires) can("delete", "gestionnaire");

      // Droits spécifiques
      if (droit.gestion) can("view", "gestion");

      // Droits pour les activités avec condition de matière
      if (droit.view_activite && matiereCondition) can("view", "activite", matiereCondition);
      if (droit.edit_activite && matiereCondition) can("edit", "activite", matiereCondition);
      if (droit.create_activite && matiereCondition) can("create", "activite", matiereCondition);
      if (droit.delete_activite && matiereCondition) can("delete", "activite", matiereCondition);

      // Droits pour les parcours avec condition de matière
      if (droit.view_parcours && matiereCondition) can("view", "parcours", matiereCondition);
      if (droit.create_parcours && matiereCondition) can("create", "parcours", matiereCondition);
      if (droit.edit_parcours && matiereCondition) can("edit", "parcours", matiereCondition);
      if (droit.delete_parcours && matiereCondition) can("delete", "parcours", matiereCondition);

      // Droits pour les validations avec condition de matière
      if (droit.view_validation && matiereCondition) can("view", "validation", matiereCondition);
      if (droit.create_validation && matiereCondition) can("create", "validation", matiereCondition);
      if (droit.edit_validation && matiereCondition) can("edit", "validation", matiereCondition);
      if (droit.delete_validation && matiereCondition) can("delete", "validation", matiereCondition);

      // Nouveaux droits ajoutés
      if (droit.reglages_prof) can("reglages", "professeur");
      if (droit.reglages_gestionnaire) can("reglages", "gestionnaire");

      if (droit.create_utilisateurs_etablissement) can("create", "utilisateurs_etablissement");
      if (droit.edit_utilisateurs_etablissement) can("edit", "utilisateurs_etablissement");
      if (droit.delete_utilisateurs_etablissement) can("delete", "utilisateurs_etablissement");
      if (droit.gestion_referentiel) can("manage", "referentiel");
    });
  });
};



// Material Dashboard 2 PRO React main context and reducer functions
const MaterialUI = createContext();

function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV":
      return { ...state, miniSidenav: action.value };
    case "TRANSPARENT_SIDENAV":
      return { ...state, transparentSidenav: action.value };
    case "WHITE_SIDENAV":
      return { ...state, whiteSidenav: action.value };
    case "SIDENAV_COLOR":
      return { ...state, sidenavColor: action.value };
    case "TRANSPARENT_NAVBAR":
      return { ...state, transparentNavbar: action.value };
    case "FIXED_NAVBAR":
      return { ...state, fixedNavbar: action.value };
    case "OPEN_CONFIGURATOR":
      return { ...state, openConfigurator: action.value };
    case "DIRECTION":
      return { ...state, direction: action.value };
    case "LAYOUT":
      return { ...state, layout: action.value };
    case "DARKMODE":
      return { ...state, darkMode: action.value };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

function useMaterialUIController() {
  const context = useContext(MaterialUI);
  if (!context) {
    throw new Error("useMaterialUIController should be used inside the MaterialUIControllerProvider.");
  }
  return context;
}

MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node,
};

// Export des fonctions de contrôle
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};
