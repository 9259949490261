import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import profilePicture from "assets/images/team-3.jpg";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled } from "@mui/material/styles";
import { useMaterialUIController, setTransparentNavbar } from "context";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import useStore from "services/store";
import { AuthContext } from "context";
import MDTypography from "components/MDTypography";
import SecureLS from "secure-ls";

const secureLocalStorage = new SecureLS({ encodingType: "aes" });

// Styled components
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.95)",
  backdropFilter: "saturate(180%) blur(10px)",
  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
  borderRadius: "0 0 16px 16px",
  borderBottom: "1px solid #e0e0e0",
  transition: "transform 0.3s ease-in-out",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  maxWidth: "1260px",
  width: "100%",
  margin: "0 auto",
  minHeight: "64px", // Réduit de 90px à 64px
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: theme.spacing(0.5), // Réduit le padding
  paddingBottom: theme.spacing(0.5),
}));

const FilterPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#ffffff",
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(2),
  },
}));

const NotificationPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#ffffff",
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    width: "300px",
  },
}));

const SelectedVariable = styled(MDTypography, {
  shouldForwardProp: (prop) => prop !== "colorVariant",
})(({ theme, colorVariant }) => ({
  fontSize: "0.8rem", // Réduit la taille de la police
  color: colorVariant ? theme.palette[colorVariant].main : "#444444",
  margin: theme.spacing(0, 0.5), // Réduit les marges horizontales
  whiteSpace: "nowrap",
}));

function DashboardNavbar({ absolute, light, isMini, breadcrumbTitle = null }) {
  const { userUid, userIdentite, role, ability, isAuthenticated, logout, loading } = useContext(AuthContext);
  const {
    selectedEtab,
    setSelectedEtab,
    selectedSection,
    setSelectedSection,
    selectedPromotion,
    setSelectedPromotion,
    selectedMatiere,
    setSelectedMatiere,
    userId,
    setUserId,
    selectedReferentiel,
    setSelectedReferentiel,
    setRole
  } = useStore();

  const [controller, dispatch] = useMaterialUIController();
  const { fixedNavbar } = controller;
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const navigate = useNavigate();
  const [etabs, setEtabs] = useState([]);
  const [sections, setSections] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [matieres, setMatieres] = useState([]);
  const [eleves, setEleves] = useState([]);
  const [referentiels, setReferentiels] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorNotifications, setAnchorNotifications] = useState(null);
  const [anchorFilter, setAnchorFilter] = useState(null);
  const [anchorSettings, setAnchorSettings] = useState(null);

  const [gestion, setGestion] = useState(false);

  // Gestion de la transparence de la navbar
  useEffect(() => {
    if (fixedNavbar) setTransparentNavbar(dispatch, true);
  }, [dispatch, fixedNavbar]);

  // Gestion du menu utilisateur
  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleMenuSelect = async (option) => {
    setAnchorEl(null);
    if (option === "Profil") {
      navigate("/pages/account/settings");
    } else if (option === "Déconnexion") {
      try {
        await useStore.persist.clearStorage();
        localStorage.removeItem("token");
        localStorage.removeItem("user-store");
        useStore.getState().reset();
        await logout();
      } catch (err) {
        console.error(err);
        return;
      }
      // Logout gère déjà la redirection
    }
  };

  // Gestion du menu Réglages
  const handleSettingsClick = (event) => {
    if (gestion) {
      setAnchorSettings(event.currentTarget);
    }
  };
  const handleSettingsClose = () => setAnchorSettings(null);

  const handleSettingsSelect = (path) => {
    handleSettingsClose();
    navigate(path);
  };

  // Gestion du défilement pour cacher/afficher la navbar
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 100) setShowNavbar(false);
      else setShowNavbar(true);
      setLastScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  // Fetch des établissements
  useEffect(() => {
    const fetchEtabs = async () => {
      if (isAuthenticated && userUid) {
        console.log("useridentite", userIdentite)
        try {
          
          const response = await crudsServiceFAPI.getEtablissementsParUser(userUid);
          console.log("getEtablissementsParUser", response)
          const etabsData = response.etablissements || [];
          setEtabs(etabsData);
          
          if (selectedEtab) {
            console.log("etabsdata", selectedEtab)
            setRole(selectedEtab.role);
          }

          if (
            !selectedEtab ||
            !etabsData.some(
              (etab) => etab.identifiant_etablissement === selectedEtab.identifiant_etablissement
            )
          ) {
            console.log("selectedEtab", selectedEtab)
            setSelectedEtab(etabsData[0] || null);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des établissements :", error);
          setEtabs([]);
        }
      }
    };
    
    fetchEtabs();
  }, [userUid, selectedEtab, setSelectedEtab, role, isAuthenticated]);

  // Fetch des sections
  useEffect(() => {
    const fetchSections = async () => {
      if (selectedEtab) {
        if (role === "eleve"){
          let sectionsData = [
            {
              identifiant_section: userIdentite.section.identifiant_section,
              denomination_section: userIdentite.section.denomination_section,
            },
          ];
          setSelectedSection(sectionsData[0]);
        }else{
        try {
          const response = await crudsServiceFAPI.getSectionsByEtabId(selectedEtab.identifiant_etablissement);
          console.log("getSectionsByEtabId", response)
          setSections(response || []);
          
          if (!selectedSection || !response.some((section) => section.identifiant_section === selectedSection.identifiant_section)) {
            setSelectedSection(response[0] || null);
          }
          
        } catch (error) {
          console.error("Erreur lors de la récupération des sections :", error);
          setSections([]);
        }
      }} else {
        setSections([]);
      }
    };
    
    fetchSections();
  }, [selectedEtab]);

  // Fetch des promotions
  useEffect(() => {
    const fetchPromotions = async () => {
      if (selectedSection) {
        console.log("michou :", role)
        try {
          let promotionsData;
          if (["professeur", "administrateur", "gestionnaire", "superadministrateur"].includes(role)) {
            const response = await crudsServiceFAPI.getPromotionsParProfSection(
              userUid,
              selectedSection.identifiant_section
            );
            promotionsData = response || [];
            setPromotions(promotionsData);
            if (!selectedPromotion || !promotionsData.some((promotion) => promotion.identifiant_promotion === selectedPromotion.identifiant_promotion)) {
              setSelectedPromotion(promotionsData[0] || null);
            }
          } else if (role === "eleve") {

            promotionsData = [
              {
                identifiant_promotion: userIdentite.promotion.identifiant_promotion,
                denomination_promotion: userIdentite.promotion.denomination_promotion,
              },
            ];
            setSelectedPromotion(promotionsData[0]);
          } else {
            const response = await crudsServiceFAPI.getPromotionsBySection(
              selectedSection.identifiant_section
            );
            promotionsData = response || [];
            setPromotions(promotionsData);
          }
          
          
          console.log("promotionsData", promotionsData)
          
        } catch (error) {
          console.error("Erreur lors de la récupération des promotions :", error);
          setPromotions([]);
        }
      } else {
        setPromotions([]);
      }
    };
    
    fetchPromotions();
  }, [selectedSection, role, userUid, userIdentite]);

  // Fetch des élèves et disciplines
  useEffect(() => {
    const fetchEleves = async () => {
      if (selectedPromotion) {
        try {
          let elevesData;
          if (role === "eleve") {
            elevesData = [
              {
                identifiant_eleve: userUid,
                nom: userIdentite.nom,
                prenom: userIdentite.prenom,
              },
            ];
            setUserId(userUid);
          } else {
            const response = await crudsServiceFAPI.getElevesByPromotion(
              selectedPromotion.identifiant_promotion
            );
            elevesData = response || [];
          }
          setEleves(elevesData);
        } catch (error) {
          console.error("Erreur lors de la récupération des élèves :", error);
          setEleves([]);
        }
      } else {
        setEleves([]);
      }
    };
    
    const fetchMatieres = async () => {
      if (selectedPromotion) {
        try {
          let matieresData;
          if (["professeur", "administrateur", "gestionnaire", "superadministrateur"].includes(role)) {
            const response = await crudsServiceFAPI.getMatieresParProfPromotion(
              userUid,
              selectedPromotion.identifiant_promotion
            );
            matieresData = response || [];
            if (!selectedMatiere || !matieresData.some((matiere) => matiere.identifiant_matiere === selectedMatiere.identifiant_matiere)) {
              setSelectedMatiere(matieresData[0] || null);
            }
          } else {
            const response = await crudsServiceFAPI.getMatieresByPromotion(
              selectedPromotion.identifiant_promotion
            );
            matieresData = response || [];
          }
          setMatieres(matieresData);
        } catch (error) {
          console.error("Erreur lors de la récupération des matières :", error);
          setMatieres([]);
        }
      } else {
        setMatieres([]);
      }
    };
    
    fetchEleves();
    fetchMatieres();
  }, [selectedPromotion, role, userUid, userIdentite]);

  // Gestion des changements dans les Autocomplete
  const handleEtabChange = (event, newValue) => {
    setSelectedEtab(newValue);
    setSelectedSection(null);
    setSelectedPromotion(null);
    setSelectedMatiere(null);
    setUserId(null);
    setSelectedReferentiel(null);
    setSections([]);
    setPromotions([]);
    setMatieres([]);
    setEleves([]);
    setReferentiels([]);
  };

  const handleSectionChange = (event, newValue) => {
    setSelectedSection(newValue);
    setSelectedPromotion(null);
    setSelectedMatiere(null);
    setUserId(null);
    setSelectedReferentiel(null);
    setPromotions([]);
    setMatieres([]);
    setEleves([]);
    setReferentiels([]);
  };

  const handlePromotionChange = (event, newValue) => {
    setSelectedPromotion(newValue);
    setSelectedMatiere(null);
    setUserId(null);
    setSelectedReferentiel(null);
    setMatieres([]);
    setEleves([]);
    setReferentiels([]);
  };

  const handleMatiereChange = (event, newValue) => {
    setSelectedMatiere(newValue);
  };

  const handleEleveChange = (event, newValue) => {
    if (newValue) {
      setUserId(newValue.identifiant_eleve);
    } else {
      setUserId(null);
    }
  };

  // Gestion des Popover
  const handleFilterClick = (event) => {
    setAnchorFilter(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorFilter(null);
  };

  const handleNotificationsClick = (event) => {
    setAnchorNotifications(event.currentTarget);
  };
  const handleNotificationsClose = () => {
    setAnchorNotifications(null);
  };

  // Vérification des permissions
  useEffect(() => {
    const checkPermission = () => {
      if (ability) {
        const hasPermission = ability.can("view", "gestion");
        console.log("Permission de voir 'gestion' : ", hasPermission);
        setGestion(hasPermission);
      }
    };

    checkPermission();
  }, [ability]);

  // Réinitialisation des sélections si l'utilisateur n'est plus authentifié
  useEffect(() => {
    if (!isAuthenticated) {
      setSelectedEtab(null);
      setSelectedSection(null);
      setSelectedPromotion(null);
      setSelectedMatiere(null);
      setUserId(null);
      setSelectedReferentiel(null);
      setSections([]);
      setPromotions([]);
      setMatieres([]);
      setEleves([]);
      setReferentiels([]);
    }
  }, [
    isAuthenticated,
    setSelectedEtab,
    setSelectedSection,
    setSelectedPromotion,
    setSelectedMatiere,
    setUserId,
    setSelectedReferentiel
  ]);

  // Notifications fictives
  const fakeNotifications = [
    { id: 1, message: "Nouvelle mise à jour disponible." },
    { id: 2, message: "Votre profil a été mis à jour." },
    { id: 3, message: "Vous avez une nouvelle demande de connexion." },
  ];

  return (
    <Box sx={{ marginBottom: 2 }}>
      <StyledAppBar position="sticky" elevation={0}>
        <StyledToolbar>
          {/* Section gauche : Icône de filtre et Réglages */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            
            <IconButton onClick={handleFilterClick} sx={{ color: "#333", padding: 0.5 }}>
              <FilterListIcon />
            </IconButton>
       
            {/* Icône Réglages avec Menu Déroulant, rendu conditionnel */}
            {gestion && (
              <IconButton onClick={handleSettingsClick} sx={{ color: "#333", padding: 0.5 }}>
                <SettingsIcon />
              </IconButton>
            )}

            {/* Popover pour les filtres */}
            <FilterPopover
              open={Boolean(anchorFilter)}
              anchorEl={anchorFilter}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2 }}>
              <Autocomplete
                  options={etabs || []}
                  getOptionLabel={(option) => option.denomination_etablissement || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.identifiant_etablissement === value.identifiant_etablissement
                  }
                  value={selectedEtab || null}
                  onChange={handleEtabChange}
                  renderInput={(params) => <TextField {...params} label="Établissement" variant="outlined" />}
                  disabled={false}
                  sx={{ minWidth: 200 }}
                />

                <Autocomplete
                  options={sections || []}
                  getOptionLabel={(option) => option.denomination_section || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.identifiant_section === value.identifiant_section
                  }
                  value={selectedSection || null}
                  onChange={handleSectionChange}
                  renderInput={(params) => <TextField {...params} label="Section" variant="outlined" />}
                  disabled={!selectedEtab}
                  sx={{ minWidth: 200 }}
                />

                <Autocomplete
                  options={promotions || []}
                  getOptionLabel={(option) => option.denomination_promotion || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.identifiant_promotion === value.identifiant_promotion
                  }
                  value={selectedPromotion || null}
                  onChange={handlePromotionChange}
                  renderInput={(params) => <TextField {...params} label="Promotion" variant="outlined" />}
                  disabled={!selectedSection}
                  sx={{ minWidth: 200 }}
                />

                <Autocomplete
                  options={matieres || []}
                  getOptionLabel={(option) => option.denomination_matiere || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.identifiant_matiere === value.identifiant_matiere
                  }
                  value={selectedMatiere || null}
                  onChange={handleMatiereChange}
                  renderInput={(params) => <TextField {...params} label="Discipline" variant="outlined" />}
                  disabled={!selectedPromotion}
                  sx={{ minWidth: 200 }}
                />

                <Autocomplete
                  options={eleves || []}
                  getOptionLabel={(option) => `${option.prenom} ${option.nom}` || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.identifiant_eleve === value.identifiant_eleve
                  }
                  value={eleves.find((e) => e.identifiant_eleve === userId) || null}
                  onChange={handleEleveChange}
                  renderInput={(params) => <TextField {...params} label="Élève" variant="outlined" />}
                  disabled={!selectedPromotion}
                  sx={{ minWidth: 200 }}
                />

              </Box>
            </FilterPopover>

            {/* Menu Réglages */}
            {gestion && (
              <Menu
                anchorEl={anchorSettings}
                open={Boolean(anchorSettings)}
                onClose={handleSettingsClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {gestion && (
                <MenuItem onClick={() => handleSettingsSelect("/examples-api/etab-management")}>
                  Gérer l'établissement
                </MenuItem>
                )}
                {ability.can("manage", "referentiel") && (
                <MenuItem onClick={() => handleSettingsSelect("/examples-api/item-management")}>
                  Gérer le référentiel
                </MenuItem>
                )}
              </Menu>
            )}
          </Box>

          {/* Section centrale pour les variables sélectionnées */}
          <Box sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            {selectedEtab && (
              <SelectedVariable colorVariant="info">
                {selectedEtab.denomination_etablissement}
              </SelectedVariable>
            )}
            {selectedSection && (
              <SelectedVariable colorVariant="success">
                {selectedSection.denomination_section}
              </SelectedVariable>
            )}
            {selectedPromotion && (
              <SelectedVariable colorVariant="warning">
                {selectedPromotion.denomination_promotion}
              </SelectedVariable>
            )}
            {selectedMatiere && (
              <SelectedVariable colorVariant="error">
                {selectedMatiere.denomination_matiere}
              </SelectedVariable>
            )}
            {userId && (
              <SelectedVariable colorVariant="primary">
                {userId}
              </SelectedVariable>
            )}
          </Box>

          {/* Section droite pour les notifications, email et avatar */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {/* Icône de notifications */}
            <IconButton onClick={handleNotificationsClick} sx={{ color: "#333", padding: 0.5 }}>
              <NotificationsIcon />
            </IconButton>
            {/* Popover pour les notifications */}
            <NotificationPopover
              open={Boolean(anchorNotifications)}
              anchorEl={anchorNotifications}
              onClose={handleNotificationsClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Notifications
              </Typography>
              {fakeNotifications.map((notif) => (
                <Box key={notif.id} sx={{ mb: 1 }}>
                  <Typography variant="body2" color="textPrimary">
                    {notif.message}
                  </Typography>
                </Box>
              ))}
              {fakeNotifications.length === 0 && (
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Aucune notification
                  </Typography>
                </Box>
              )}
            </NotificationPopover>

            {/* Adresse email stylisée avec MDTypography */}
            <MDTypography variant="body2" color="info" sx={{ textDecoration: "underline", fontSize: "0.8rem" }}>
              {userIdentite?.email}
            </MDTypography>

            {/* Avatar utilisateur et menu */}
            <IconButton onClick={handleMenuClick} sx={{ color: "#333", padding: 0.5 }}>
              <Avatar alt="Profil" src={profilePicture} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={() => handleMenuSelect("Profil")}>Profil</MenuItem>
              <MenuItem onClick={() => handleMenuSelect("Déconnexion")}>Déconnexion</MenuItem>
            </Menu>
          </Box>
        </StyledToolbar>
      </StyledAppBar>
    </Box>
  );
}

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
